<template>
  <div class="data-mini-bars">
    <div :class="`bars mr-3 bar-${index}`" :title="value" :style="`--h_bar-${index}: ${formatHeight(value)}px;`"></div>
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
      default: () => 0
    },
    value: {
      type: Number,
      default: () => 0
    },
    comparison: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {}
  },
  methods: {
    formatHeight(item) {
      const before = this.model.before_value;
      const current = this.model.current_value;
      
      if (this.comparison) {
        return current == item ? 100 : ((before / current) * 100) < 1 ? 10 : ((before / current) * 100);
      } else {
        return before == item ? 100 : ((current / before) * 100) < 1 ? 10 : ((current / before) * 100);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.data-mini-bars {
  position: relative;
  display: flex;
  align-items: flex-end;
  max-height: 80%;
  height: 100%;
  overflow-y: hidden;

  .bars { 
    width: .75rem;
    border-radius: .1rem;
    max-height: var(--h_base);
    background-color: #e8e8e8;
    transition: .25s;
    height: 0;
  }
  .bar-0 {
    height: var(--h_bar-0);
  }
  .bar-1 {
    height: var(--h_bar-1);
    background-color: #2981EF;
  }
}
</style>