<template>
  <div v-if="model" :class="{ 'data-card-cart': true, sm: size == 'sm', 'w-100': w_100, hover_card: hover }">
    <div v-if="icon" :class="{ 'icon-box mr-3': true, price: type == 'price' }">
      <i v-if="type == 'price'" class="fas fa-money-bill" />
      <i v-else class="fas fa-comment-alt" />
    </div>
    <div class="body_box mr-4">
      <p class="label mb-0">{{ model.label }}</p>
      <p v-if="type == 'percent'" class="quantity mb-1">{{ setFormatNumber(model.current_value) }}<span class="symbol ml-1 mb-0">%</span></p>
      <p v-else-if="type == 'price'" class="quantity mb-1">{{ setFormatNumber(model.current_value) }}<span class="symbol">{{ lang != 'es' ? ' USD' : ' CLP' }}</span></p>
      <p v-else class="quantity mb-1">{{ setFormatNumber(model.current_value) }}</p>
      <div v-if="comparison" :class="{ percent: true, positive: model.current_value > model.before_value }"><span class="fas fa-sort-up mr-2 mt-2" />+{{ diferencePercent }}%</div>
      <div v-else class="percent negative"><span class="fas fa-sort-down mr-2 mb-2" />-{{ diferencePercent }}%</div>
    </div>
    <template v-if="chat_bars">
      <DataMiniBars
        v-for="(bar, index) in [model.before_value, model.current_value]"
        :key="index"
        :index="index"
        :value="bar"
        :model="model"
        :comparison="comparison"
      />
    </template>
  </div>
</template>

<script>
import DataMiniBars from './DataMiniBars';

export default {
  components: { DataMiniBars },

  props: {
    type: {
      type: String,
      default: () => ''
    },
    lang: {
      type: String,
      default: () => 'es'
    },
    size: {
      type: String,
      default: () => ''
    },
    w_100: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => {}
    },
    icon: {
      type: Boolean,
      default: () => false
    },
    chat_bars: {
      type: Boolean,
      default: () => false
    },
    hover: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {

    }
  },
  computed: {
    diferencePercent() {
      if (this.model) {
        // Porcentaje de diferencia vs registro anterior
        const old = this.model.before_value;
        const now = this.model.current_value;
        const total = ((now * 100) / old) - 100;
        console.log("total->", total.toString().length);

        return total.toFixed(0);
      }
    },
    comparison() {
      if (this.model) return this.model.current_value >= this.model.before_value;
    }
  },
  methods: {
    percentFormat(number) {
      let value = number;
      return number > 1 ? number : number = value * 100;
    },
    setFormatNumber(number) {
      switch (this.type) {
        case "price":
          return this.lang != 'es' ? Intl.NumberFormat('en-US').format(number) : Intl.NumberFormat('de-DE').format(number);
        case "percent":
          return this.percentFormat(number);
        default:
          return Intl.NumberFormat('de-DE').format(number);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.data-card-cart {
  --h_base: 150px;
  width: fit-content;
  max-height: var(--h_base);
  height: var(--h_base);
  min-width: fit-content;
  max-width: 100%;
  padding: 1.5rem;
  border: 1px solid #e8e8e8;
  background-color: #fff;
  border-radius: 1.5rem;
  display: flex;
  align-items: flex-end;
  transition: .25s;
  margin: .5rem 0;

  &.hover_card:hover {
    transform: translate(-0.25rem, -0.25rem);
    box-shadow: 1rem 1rem 30px #D8EAFF;
  }
  &:hover {
    .fa-sort-up {
      animation: 1s hover-up ease-in-out infinite;
    }
    .fa-sort-down {
      animation: 1s hover-down ease-in-out infinite;
    }
  }
  .icon-box {
    width: 100px;
    height: 100%;
    max-width: 100%;
    border-radius: .75rem;
    padding: 1rem;
    background-color: #E3F0FF;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      width: 100%;
      text-align: center;
      font-size: 2.5rem;
      color: #BFDCFF;
      margin: 0 auto;
    }
  }
  .icon-box.price {
    background-color: #DDFFDB;

    i {
      color: #7AD676;
    }
  }
  .body_box {
    width: fit-content;
  }
  .label {
    font-size: 12px;
    color: #767676;
  }
  .quantity {
    font-size: 1.75rem;
    font-weight: 700;
    color: #181818;
  }
  .percent {
    width: fit-content;
    display: flex;
    align-items: center;
    padding: .25rem .5rem;
    border-radius: .5rem;
    color: #767676;
    background-color: #f2f2f2;
    
    &.positive {
      color: #2981EF;
      background-color: #E3F0FF;
      
      span {
        color: #2981EF;
      }
    }
    &.negative {
      color: #ff3860;
      background-color: #FFE3E3;

      span {
        color: #ff3860;
      }
    }
  }
  &.sm {
    padding: 1rem;
    border-radius: 1rem;
  }
  .symbol {
    font-size: 1rem;
    color: #cdcdcd;
  }
  // .bar_box {
  //   position: relative;
  //   display: flex;
  //   align-items: flex-end;
  //   height: 100%;
  //   bottom: 0;
  //   overflow-y: hidden;
  // }
  // .bars { 
  //   width: .75rem;
  //   border-radius: .1rem;
  //   max-height: var(--h_base);
  //   background-color: #e8e8e8;
  //   transition: .25s;
  //   height: 0;
  // }
  // .bar-1 {
  //   height: var(--h_bar-1);
  // }
  // .bar-2 {
  //   height: var(--h_bar-2);
  //   background-color: #2981EF;
  // }
}
.w-100 .body_box {
  width: 100%;
}
@keyframes hover-up {
  0% { transform: translateY(0)}
  50% { transform: translateY(-5px) }
  100% { transform: translateY(-1px) }
}
@keyframes hover-down {
  0% { transform: translateY(0)}
  50% { transform: translateY(5px) }
  100% { transform: translateY(1px) }
}
</style>