<template>
  <section class="integration_cms">
    <template v-if="!selected_platform.name">
      <div class="row align-items-center justify-content-center">
        <div class="col-md-6">
          <div class="row justify-content-center">
            <div class="col-lg-9">
              <h1 class="mb-5">Integra y sincroniza el conocimiento de tu agente virtual con los productos e inventarios de tu CMS.</h1>
              <br>
              <label class="d-block mb-5" for="">Selecciona un CMS:</label>
              <div class="row">
                <div v-for="(platform, index) in filtered_platforms" :key="index" class="item" @click="selected_platform = platform">
                  <img class="logo" :src="platform.logo" :alt="platform.name">
                </div>
              </div>
              <div class="label-box">
                <span class="d-block click my-5" @click="stepBack">{{ "<- No tengo eCommerce" }}</span>
                <router-link class="d-block click my-5" to="/dashboard">Más tarde -></router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <img class="banner-1" src="/img/banners/banner-register-3.jpg" alt="">
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-5">
          <img class="logo_lg row justify-content-center" :src="selected_platform.logo" :alt="selected_platform.name">
          <h1 class="text-center mb-3">Agrega tus credenciales de {{ selected_platform.name }}.</h1>
          <p class="text-center mb-5">Al sincronizar tu tienda podrás optimizar tus operaciones, ampliar la gama de beneficios y funcionalidades que te permitirán alcanzar el éxito en el mundo del comercio electrónico.</p>
          <div class="row align-items-center justify-content-center">
            <div class="col-lg-10">
              <DataInput
                v-for="(credentials, index) in selected_platform.credentials"
                :key="index"
                :label="credentials.label"
                :type="credentials.type"
                :model="credentials.value"
                :error="credentials.error"
                @newValue="credentials.value = $event, showContentField($event)"
                required
              />
              <!-- <p v-if="noTutorials" class="text-center mb-5">¿Necesitas ayuda? <span class="link click" @click="show_tutorial = true">Ver tutorial -></span>
              </p> -->
              <br />
              <button class="btn btn-base second w-100 lg" @click="updateCMS" :disabled="is_empty">Integrar CMS</button>
              <p class="text-center my-3 click" @click="selected_platform = {}">{{ '<- Cambiar CMS' }}</p>
            </div>
          </div>
        </div>
        <div v-if="selected_platform.video" class="preview_video col-lg-4">
          <SpinnerLoading v-show="loading" />
          <iframe
            @load="loading = false"
            v-show="!loading"
            class="iframe_box"
            :src="selected_platform.video"
            :title="selected_platform.name"
            width="100%"
            height="250"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          />
        </div>
      </div>
    </template>
    <ShowTutorial
      :showModal="show_tutorial"
      :selected_platform="selected_platform"
      @closeModal="show_tutorial = false"
    />
  </section>
</template>

<script>
import { FadeTransition } from "vue2-transitions";
import { mapState, mapMutations } from "vuex";
import axios from "axios";
import dashboard_api from "@/dashboard_api";
import DataInput from "@/components/builders/DataInput";
import ShowTutorial from "@/views/V2/Auth/components/ShowTutorial";
import enviroment from '@/environment';
import socketTest from '@/plugins/socketAutomatizaciones';

export default {
  components: {
    FadeTransition,
    DataInput,
    ShowTutorial,
    socketTest
  },
  data() {
    return {
      is_empty: true,
      loading: true,
      selected_platform: {},
      show_tutorial: false,
      platforms: [
        {
          name: "Shopify",
          logo: "/img/logos/2-implementacion/shopify.svg",
          video: "https://www.youtube.com/embed/rgIt6_Mfw2Y?si=PqmVLhznnfO3Bav-",
          show_credentials: false,
          credentials: [
            {
              label: "Dominio shopify",
              key: "domain",
              type: "url",
              value: ""
            },
            {
              label: "Api secret key",
              key: "key",
              type: "text",
              value: ""
            },
          ]
        },
        {
          name: "Vtex",
          logo: "/img/logos/2-implementacion/vtex.svg",
          video: "https://www.youtube.com/embed/5f9fxhLGB3E?si=cIGamLXjhohqA6Mh",
          show_credentials: false,
          credentials: [
            {
              label: "Dominio Vtex",
              key: "app_domain",
              type: "url",
              value: "",
              // value: "https://databotpartnercl.myvtex.com"
              // value: "https://www.supletech.cl"
            },
            {
              label: "App key Vtex",
              key: "app_key",
              type: "text",
              value: ""
              // value: "vtexappkey-databotpartnercl-UADBAB"
              // value: "vtexappkey-supletech-RCECPH"
            },
            {
              label: "App token",
              key: "app_token",
              type: "text",
              value: ""
              // value: "CUALJLEKLMYRZCBQBVJJHRTIJMCZZPCUTUHEYCHFDBOHNNOUJZMQOOPDYYLUCLQZUINTBJTTNEPDAJISFNQMNCYQUTQDEULNQYULKSAPYFPWUDLBWSMIXGITBKQCGLCU"
              // value: "GFVCLOGQPGPLYXJQJPNZZLELTDGCWEJRVSFOTZATDQPXZXUAMFYCNQDOFSWRRNBBUMLIRKIXRXTIZIKVTMFEFPABVTJEKXMMGZJICQDMTHLYSQKXJRFQPHKDQOVCBQBP"
            },
          ]
        },
        {
          name: "Jumpseller",
          logo: "/img/logos/2-implementacion/jumpseller-2.svg",
          video: "https://www.youtube.com/embed/cx9RHT6T6fA?si=oMMLux02GL67ni6i",
          show_credentials: false,
          credentials: [
            {
              label: "Dominio Jumpseller",
              key: "jdomain",
              type: "url",
              value: ""
            },
            {
              label: "Login",
              key: "login",
              type: "text",
              value: ""
            },
            {
              label: "Token",
              key: "authToken",
              type: "text",
              value: ""
            },
          ]
        },
        {
          name: "Adobe Commerce",
          logo: "/img/logos/2-implementacion/adobe-commerce.svg",
          show_credentials: false,
          credentials: [
            {
              label: "Dominio Adobe",
              key: "mg_domain",
              type: "url",
              value: ""
            },  
            {
              label: "Tienda",
              key: "mg_store_code",
              type: "text",
              value: ""
            },
            {
              label: "Token",
              key: "mg_token",
              type: "text",
              value: ""
            },
          ]
        },
        {
          name: "Woocommerce",
          logo: "/img/logos/2-implementacion/woocommerce.svg",
          show_credentials: false,
          credentials: [
            {
              label: "Key",
              key: "wkey",
              type: "text",
              value: ""
            },
            {
              label: "Secret",
              key: "secret",
              type: "text",
              value: ""
            },
            {
              label: "Dominio Woocommerce",
              key: "wdomain",
              type: "url",
              value: ""
            },
          ]
        },
        {
          name: "Bsale",
          logo: "/img/logos/2-implementacion/bsale-2.svg",
          show_credentials: false,
          credentials: [
            {
              label: "Dominio",
              key: "bdomain",
              type: "url",
              value: ""
            },
            {
              label: "ID Market",
              key: "id_market",
              type: "text",
              value: ""
            },
            {
              label: "Token",
              key: "btoken",
              type: "text",
              value: ""
            },
          ]
        },
      ]
    }
  },
  mounted() {
    this.validatePlatform();
    console.log("registerData CMS->", this.registerData);
  },
  computed: {
    ...mapState(["ownerBots", "user", "platform_name", "registerData"]),

    filtered_platforms() {
      if (!this.platform_name || this.platform_name == 'databot') return this.platforms;
      return this.platforms.filter(el => el.name.toLocaleLowerCase() == this.platform_name);
    },
    noTutorials() {
      if (this.selected_platform.name) return !["Magento"].includes(this.selected_platform.name);
    }
  },
  watch: {
    selected_platform(val) {
      if (val?.name) {
        let index = val.credentials.findIndex(el => el.type == 'url')
        // console.log("item->", index);
        return this.selected_platform.credentials[index].value = 'https://' + this.user?.url;
      };
    }
  },
  methods: {
    ...mapMutations(["setProductsReady", "setEcommerceAccount", "setRegisterProcess"]),

    stepBack() {
      let item = this.registerData;
      item.step = this.platform_name ? 5 : 4;
      this.setRegisterProcess(item);
    },
    validatePlatform() {
      const names = this.platforms.map(cred => cred.name.toLowerCase());
      const origin = this.platform_name;
      
      // Valida si es que viene de algún cms
      if (names.includes(origin)) {
        this.selected_platform = this.filtered_platforms[0];
        this.show_credentials = true;
      } else this.selected_platform = {};
    },
    showContentField(event) {
      // to validate empty fields
      this.is_empty = this.selected_platform.credentials.some(el => el.value.length == 0);
    },
    formDataBody(chatbot) {
      const data = new FormData();
      const { has_skill_base } = chatbot;

      data.append("chatbot[has_skill_base]", has_skill_base);

      return data;
    },
    // updateBot() {
    //   // vuelve a guardar por si cambia has_skill_base a true/false
    //   let chatbot = this.ownerBots[0];
    //   const formData = this.formDataBody(chatbot);

    //   dashboard_api
    //     .put(`/bot/${this.registerData.bot}`, formData)
    //     .then(resp => {
    //       console.log("resp new->", resp.data);
    //     })
    //     .catch(err => console.log("err->", err));
    // },
    updateCMS() {
      this.$swal({
        icon: "info",
        title: "Buscando productos...",
        showConfirmButton: false
      });
      this.$swal.showLoading();
      this.updateBot();

      let credentials = {};
      this.selected_platform.credentials.forEach(cred => {
        credentials = {
          ...credentials, 
          [cred.key]: cred.value
        }
      });
      dashboard_api
        .post("/user_credentials", {
          provider: this.selected_platform.name.toLowerCase(),
          credentials: JSON.stringify(credentials),
          status: true,
          bot_id: this.registerData.bot
        },
      ).then(async resp => {
        this.$swal({
          icon: "info",
          title: "Configurando chatbot...",
          showConfirmButton: false
        });
        this.$swal.showLoading();
        // set boton ->
        await Promise.all([
          this.$store.dispatch('dtClientIntentsModule/intentCopy', {
            source_intent_name: "FAQ.comprar",
            template: "TEST001",
            bot_id: this.registerData.bot,
          }),
          this.$store.dispatch('dtClientNodesModule/nodeCopy', {
            node_id: "node_c98515bb-8b1a-4d70-941f-388c1bffb004",
            bot_id: this.registerData.bot,
            template: "TEST001",
            new_output: [
              {
                values:
                  [
                    {text: "Perfecto! Cuéntame, ¿Qué buscas?  Prueba escribiendo un producto a la vez 🔎. Prefiere el uso de palabras claves y breves"}
                  ],
                response_type: "text",
                selection_policy: "sequential"
              }
            ],
          }),
        ]);
        // Primer UI boton ->
        await this.$store.dispatch(
          'dtClientNodesModule/updateWelcomeButtons',
          {
            template: "TEST001",
            buttons: [{text: "🛍 Comprar", action: "answer", jump_to: "node_7_1662564245823"}],
            bot_id: this.registerData.bot,
          },
        );
        this.$swal({
          icon: "info",
          title: "Cargando productos...",
          showConfirmButton: false
        });
        this.$swal.showLoading();
        this.setEcommerceAccount({
          show_bar: true,
          show_done_bar: false,
          upload_progress: 10
        });
        socketTest.emit('AUTO_RECONNECT', {
          botId: this.registerData.bot
        });
        try {
          // CARGA DE PRODUCTOS
          const config = {
            method: 'POST',
            url: `${enviroment.url_automations}/charge_products_by_bot`,
            data: {
              "bot_id": this.registerData.bot
            },
          };
          let productos = await axios.request(config);

          this.$swal({
            icon: "success",
            title: `¡${this.selected_platform.name} integrado!`,
            showConfirmButton: false,
            html: `<a class="btn btn-base second lg mt-4" href="/dashboard">Ver mi dashboard</a>`
          });
          console.log("carga de productos 2->", productos);

        } catch (error) {
          console.log("loading products error",error)
          this.$swal({
            icon: "info",
            title: `Ocurrio un error`,
            text: 'No te preocupes, puedes integrar tus productos más tarde',
            showConfirmButton: false,
            html: `<a class="btn btn-base second lg mt-4" href="/dashboard">Ver mi dashboard</a>`
          });
        }
      })
      .catch(error => console.log('error->', error));
    }
  }
}
</script>

<style lang="scss" scoped>
.integration_cms {
  width: 100%;
  .item {
    width: 150px;
    padding: 1rem .5rem 1rem 0;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 2rem 2rem 0;
    cursor: pointer;
    transition: .25s;

    &:hover {
      padding: 1rem .5rem;
      box-shadow: 0 0 30px #DEEDFF;
      
      .logo {
        transform: scale(1.05);
      }
    }
    .logo {
      transition: .25s;
      width: 250px;
    }
  }
  .logo_lg {
    width: 300px;
    margin: 2rem auto;
  }
  .banner-1 {
    width: 100%;
    border-radius: 1.5rem;
  }
  .iframe_box {
    border-radius: 1rem;
    border: 1px solid #cdcdcd;
    justify-content: center;
    margin: 0 auto;
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.25);

    @media (max-width: 1600px) {
      height: 200px;
    }
  }
}
.preview_video {
  .bg-spinner {
    position: relative;
    border-radius: 1rem;
    border: 1px solid #cdcdcd;
    background-color: #fafafa;
    height: 250px;
  }
}
</style>